export default class ramphastosResourceUpdateService {

    // because: https://stackoverflow.com/a/47164318/3737186
    static get $$ngIsClass() { return true; }

    constructor($window, $rootScope) {
        this.resourceChanged = function (files) {

            var needReload = false;
            $.each(files,
                function (index, value) {
                    //strip any ? (GET parameters)
                    var n = value.indexOf('?');
                    if (n > 0) {
                        value = value.substring(0, n !== -1 ? n : s.length);
                    }
                    //check whether this is a file we need a reload 
                    if (value.endsWith(".js")
                        || value.endsWith(".png") || value.endsWith(".jpg") || value.endsWith(".bmp")
                        || value.endsWith(".gif") || value.endsWith(".ico") || value.endsWith(".svg")) {
                        needReload = true;
                    }
                });


            if (needReload) {
                sessionStorage.setItem('ramRequestResyncOnly', true);
                if ($window.onbeforeunload !== undefined) {
                    $window.onbeforeunload = undefined;
                }
                location.reload(); //simple pragmatic solution
            } else {
                $.each(files,
                    function (index, value) {
                        //strip any ? (GET parameters)
                        var n = value.indexOf('?');
                        if (n > 0) {
                            value = value.substring(0, n !== -1 ? n : s.length);
                        }

                        if (value.endsWith(".css")) {
                            var linkElements = $('head > link[href^="' + value + '"]');
                            $.each(linkElements, function (i, v) {
                                if (value.includes('?')) {
                                    v.href = value + '&ts=' + new Date().getTime();
                                } else {
                                    v.href = value + '?ts=' + new Date().getTime();
                                }

                            });
                        }
                        else if (value.endsWith(".html") || value.endsWith(".htm")) {
                            $rootScope.$broadcast('ramTemplateChanged', value);
                        }
                    });
            }

        };
    }
}
