export default class RamphastosStringSplitService {

    // because: https://stackoverflow.com/a/47164318/3737186
    static get $$ngIsClass() { return true; }

    constructor() {
        this.split = function(string, size, multiline) {
            var matchAllToken = (multiline === true) ? '[^]' : '.';
            var re = new RegExp(matchAllToken + '{1,' + size + '}', 'g');
            return string.match(re);
        };
    }
}
