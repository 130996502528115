export default class RamphastosLoad {

    // because: https://stackoverflow.com/a/47164318/3737186
    static get $$ngIsClass() { return true; }

    /* Directive definition for file loading. With this directive files can be uploaded to the server in remote case
     * or the C# part in the locale case.
     */
    constructor(ramphastosComService, ramphastosApplicationService) {
        return {
            restrict: 'A',
            scope: {
                loadWith: '@loadWith'
            },
            link: function(scope, element) {
                element.on('change', onChange);

                scope.$on('destroy',
                    function() {
                        element.off('change', onChange);
                    });

                //TODO: provide another alternative where we just select the file and first have to press a button to start the load.
                function onChange() {
                    var files = element[0].files;
                    var ramphastosPath = ramphastosApplicationService.getRamphastosPath(scope);
                    var loadWithFunctionName = scope.loadWith.replace(/^vm./, ''); //make sure we strip potential "vm."
                    ramphastosComService.loadFile(ramphastosPath, loadWithFunctionName, files);
                    element[0].value = ""; //allow to select the same file twice
                }
            }
        };
    }
}


