export default class RamphastosDebugController {
    constructor($scope, $rootScope) {
        $scope.isObject = function (param) {
            return typeof param === 'object' && param !== null;
        };

        $scope.expandAll = function () {
            $scope.$broadcast('toggleExpand', true);
        };

        $scope.collapseAll = function () {
            $scope.$broadcast('toggleExpand', false);
        };
    }
}