import $ from 'jquery';
import angular from 'angular';
import oclazyload from 'oclazyload';

import ramphastosErrorController from './angular/ramphastosApp/controllers/ramphastosErrorController';
import ramphastosApplicationService from './angular/ramphastosApp/services/ramphastosApplicationService';
import ramphastosCopyService from './angular/ramphastosApp/services/ramphastosCopyService';
import ramphastosCyclicJsonService from './angular/ramphastosApp/services/ramphastosCyclicJsonService';
import ramphastosDiffPatchService from './angular/ramphastosApp/services/ramphastosDiffPatchService';
import ramphastosEncodingService from './angular/ramphastosApp/services/ramphastosEncodingService';
import ramphastosHTMLService from './angular/ramphastosApp/services/ramphastosHTMLService';
import ramphastosLocaleService from './angular/ramphastosApp/services/ramphastosLocaleService';
import ramphastosPropertyChangedService from './angular/ramphastosApp/services/ramphastosPropertyChangedService';
import ramphastosResourceUpdateService from './angular/ramphastosApp/services/ramphastosResourceUpdateService';
import ramphastosRoutingService from './angular/ramphastosApp/services/ramphastosRoutingService';
import ramphastosStringSplitService from './angular/ramphastosApp/services/ramphastosStringSplitService';
import ramphastosSuggestionService from './angular/ramphastosApp/services/ramphastosSuggestionService';
import ramphastosUrlLocalizeService from './angular/ramphastosApp/services/ramphastosUrlLocalizeService';
import ramphastosViewScopeFillService from './angular/ramphastosApp/services/ramphastosViewScopeFillService';
import ramphastosComStatus from './angular/ramphastosApp/directives/ramphastosComStatus';
import ramphastosComServiceFactory from './angular/ramphastosApp/factories/ramphastosComServiceFactory';
import ramphastosMainController from './angular/ramphastosApp/controllers/ramphastosMainController';
import ramlocalize from './angular/ramphastosApp/directives/ramLocalize';
import ramphastosInterceptorFunction from './angular/ramphastosApp/factories/ramphastosInterceptor';
import ramphastosView from './angular/ramphastosApp/directives/ramphastosView';
import ramphastosLoad from './angular/ramphastosApp/directives/ramphastosLoad';
import ramphastosDownload from './angular/ramphastosApp/directives/ramphastosDownload';
import ramphastosExternal from './angular/ramphastosApp/directives/ramphastosExternal'; 
import recursionHelper from './angular/ramphastosApp/factories/recursionHelper';
import ramphastosDebug from './angular/ramphastosApp/directives/ramphastosDebug';
import ramphastosDebugItem from './angular/ramphastosApp/directives/ramphastosDebugItem';
import ramphastosDebugKeyValue from './angular/ramphastosApp/directives/ramphastosDebugKeyValue';
import focusOnSwitch from './angular/ramphastosApp/directives/focusOnSwitch';
import ramphastosInspector from './angular/ramphastosApp/directives/ramphastosInspector';
import ramphastosInspectorItem from './angular/ramphastosApp/directives/ramphastosInspectorItem';
import get_browser_info from './js/get_browser_info';


global.$ = global.jQuery = $;
global.angular = angular;
global.get_browser_info = get_browser_info;


global.ramphastosEnableDebugInfoFromBoostrap = false;

export default function ramphastosBootstrap(staticModules, angularDigestTtl, enableAngularDebug, enableHtml5Mode) {
    if (enableAngularDebug) {
        console.log("DEBUG: browser-info: " + get_browser_info().name + " " + get_browser_info().version);
        console.log("DEBUG: user-agent: " + navigator.userAgent);
        console.log("DEBUG: language: " + navigator.language);
    }
    let dependencies = ['oc.lazyLoad'];
    $.each(staticModules, function (index, entry) {
        if (entry !== undefined && entry != null && entry.Name !== undefined) {
            if (enableAngularDebug) {
                console.log("Dependency: " + entry.Name);
            }
            dependencies.push(entry.Name);
        }
    });
    let ramphastosApp = angular.module('ramphastosApp', dependencies, ['$rootScopeProvider',
        function ($rootScopeProvider) { $rootScopeProvider.digestTtl(angularDigestTtl); }
    ]);
    if (!enableAngularDebug) {
        ramphastosApp.config(['$compileProvider', function ($compileProvider) { $compileProvider.debugInfoEnabled(false); }]);
    }
    if (enableHtml5Mode) {
        let routeConfiguration = ($locationProvider) => {
            // use the HTML5 History API
            $locationProvider.html5Mode(true);
        };
        ramphastosApp.config(['$locationProvider', routeConfiguration]);
    }


    // Now adding all the service, controllers, directives, etc.

    ramphastosApp.controller('ramphastosErrorController', ['$scope', 'ramphastosComService', ramphastosErrorController]);
    ramphastosApp.service('ramphastosStaticModulesInfoService', [
        function(){
            this.getStaticModules = function () { return staticModules; }
        }]);
    ramphastosApp.service('ramphastosApplicationService', [
        '$rootScope',
        '$q',
        '$ocLazyLoad',
        '$timeout',
        'ramphastosDiffPatchService',
        'ramphastosCyclicJsonService',
        'ramphastosSuggestionService',
        'ramphastosRoutingService',
        'ramphastosLocaleService',
        'ramphastosUrlLocalizeService',
        ramphastosApplicationService]);
    ramphastosApp.service('ramphastosCopyService', ['ramphastosCyclicJsonService', ramphastosCopyService]);
    ramphastosApp.service('ramphastosCyclicJsonService', [ramphastosCyclicJsonService]);
    ramphastosApp.service('ramphastosDiffPatchService', ['ramphastosCopyService', ramphastosDiffPatchService]);
    ramphastosApp.service('ramphastosEncodingService', [ramphastosEncodingService]);
    ramphastosApp.service('ramphastosHTMLService', ["$q", ramphastosHTMLService]);
    ramphastosApp.service('ramphastosLocaleService', ['$window', ramphastosLocaleService]);
    ramphastosApp.service('ramphastosPropertyChangedService',
        ['ramphastosComService',
            'ramphastosApplicationService',
            'ramphastosDiffPatchService',
            'ramphastosCyclicJsonService', ramphastosPropertyChangedService]);
    ramphastosApp.service('ramphastosResourceUpdateService', ['$window', '$rootScope', ramphastosResourceUpdateService]);
    ramphastosApp.service('ramphastosRoutingService', [
        '$rootScope',
        '$timeout',
        '$q',
        '$location',
        '$window',
        ramphastosRoutingService]);
    ramphastosApp.service('ramphastosStringSplitService', [ramphastosStringSplitService]);
    ramphastosApp.service('ramphastosSuggestionService', [ramphastosSuggestionService]);
    ramphastosApp.service('ramphastosUrlLocalizeService', ['ramphastosLocaleService', ramphastosUrlLocalizeService]);
    ramphastosApp.service('ramphastosViewScopeFillService', ['ramphastosComService', 'ramphastosApplicationService',
        'ramphastosCopyService', ramphastosViewScopeFillService]);
    ramphastosApp.directive(
        'ramphastosComStatus',
        [
            '$rootScope',
            '$timeout',
            '$window',
            ramphastosComStatus
        ]);
    ramphastosApp.factory('ramphastosComService', ['$window', '$injector', ramphastosComServiceFactory]);
    ramphastosApp.controller('ramphastosMainCtrl', ['$scope', '$rootScope', '$location', 'ramphastosComService', 'ramphastosApplicationService', ramphastosMainController]);
    ramphastosApp.filter('ramlocalize', ['ramphastosComService', ramlocalize]);
    ramphastosApp.factory('ramphastosInterceptor', ['ramphastosUrlLocalizeService', ramphastosInterceptorFunction]);
    ramphastosApp.config(['$httpProvider', function ($httpProvider) {
        $httpProvider.interceptors.push('ramphastosInterceptor');
    }]);
    ramphastosApp.directive(
        'ramphastosView',
        [
            '$rootScope',
            '$compile',
            '$timeout',
            '$templateCache',
            '$http',
            '$q',
            'ramphastosApplicationService',
            'ramphastosPropertyChangedService',
            'ramphastosViewScopeFillService',
            'ramphastosDiffPatchService',
            'ramphastosCyclicJsonService',
            ramphastosView
        ]);
    ramphastosApp.directive(
        'ramphastosLoad',
        [
            'ramphastosComService',
            'ramphastosApplicationService',
            ramphastosLoad
        ]);
    ramphastosApp.directive(
        'ramphastosDownload',
        [
            'ramphastosComService',
            'ramphastosApplicationService',
            ramphastosDownload
        ]);
    ramphastosApp.directive(
        'ramphastosExternal',
        [
            '$compile',
            '$sce',
            ramphastosExternal
        ]);
    ramphastosApp.factory('RecursionHelper', ['$compile', recursionHelper]);
    ramphastosApp.directive(
        'ramphastosDebug',
        [
            '$rootScope',
            '$compile',
            '$timeout',
            ramphastosDebug
        ]);
    ramphastosApp.directive(
        'ramphastosDebugItem',
        [
            'RecursionHelper',
            ramphastosDebugItem
        ]);
    ramphastosApp.directive(
        'ramphastosDebugKeyValue',
        [
            ramphastosDebugKeyValue
        ]);
    ramphastosApp.directive('focusOnSwitch', ['$timeout', focusOnSwitch]);
    ramphastosApp.directive(
        'ramphastosInspector',
        [
            '$rootScope',
            'ramphastosApplicationService',
            '$timeout',
            'ramphastosComService',
            'ramphastosStaticModulesInfoService',
            ramphastosInspector
        ]);
    ramphastosApp.directive(
        'ramphastosInspectorItem',
        [
            'RecursionHelper',
            ramphastosInspectorItem
        ]);

    // init client script (e.g. CefGlue)
    if (window.ramphastosInitServerLink !== undefined) {
        window.ramphastosInitServerLink(ramphastosApp);
    }

    global.ramphastosEnableDebugInfoFromBoostrap = enableAngularDebug;

    let injector = angular.bootstrap(document, ['ramphastosApp']);
    let applicationService = injector.get("ramphastosApplicationService");
    applicationService.debugInfoEnabled(enableAngularDebug);


};
