import JsonNetDecycle from './../../../js/JsonNetDecycle';

export default class RamphastosCyclicJsonService {

    // because: https://stackoverflow.com/a/47164318/3737186
    static get $$ngIsClass() { return true; }

    constructor() {

        var jsonNetDecylceInstance = new JsonNetDecycle();

        /*
          * create a an object containing $id and $ref properties.
          */
        this.decycle = function(object) {
            if (object !== null &&
                object !== undefined &&
                (typeof object === 'object' || object.constructor === Array)) {
                var decycled = jsonNetDecylceInstance.decycle(object);
                return decycled;
            }
            return object;
        };

        /**
         * serialize object to cyclic JSON (CJSON)
         * @param {Object} object The object to be serialized
         * @returns {string} CJSON string
         */
        this.toCJSON = function (object) {
            // ReSharper disable once MisuseOfOwnerFunctionThis
            var decycled = this.decycle(object);
            return angular.toJson(decycled);
        }.bind(this);



        /**
         * create a an object with real properties, not containing $id and $ref properties any more.
         * @param {Object} cycled An object with  $id and $ref properties
         * @returns {Object} object with references
         */
        this.retrocycle = function (cycled) {
            if (cycled !== null && cycled !== undefined && (typeof cycled === 'object' || cycled.constructor === Array)) {
                var object = jsonNetDecylceInstance.retrocycle(cycled);
                return object;
            }
            return cycled;
        }

        /**
         * translate CJSON to object
         * @param {string} cjson A string of cyclic json (CJSON)
         * @returns {Object} the deserialized object
         */
        this.fromCJSON = function (cjson) {
            var cycled = JSON.parse(cjson);
            // ReSharper disable once MisuseOfOwnerFunctionThis
            return this.retrocycle(cycled);
        }.bind(this);
    }
}