export default class FocusOnSwitch {

    // because: https://stackoverflow.com/a/47164318/3737186
    static get $$ngIsClass() { return true; }

    constructor($timeout) {
        return {
            restrict: 'A',
            link: function($scope, $element, $attr) {
                $timeout(function() {
                        $element.focus();
                    },
                    0,
                    false);
            }
        };
    }
}