export default class RamphastosCopyService {

    // because: https://stackoverflow.com/a/47164318/3737186
    static get $$ngIsClass() { return true; }

    constructor(ramphastosCyclicJsonService) {
        this.copy = function(toCopy) {
            return ramphastosCyclicJsonService.retrocycle(ramphastosCyclicJsonService.decycle(toCopy));
        };
    }
}