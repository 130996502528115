import $ from 'jquery';
import ramphastosViewController from './../controllers/ramphastosViewController';

export default class RamphastosView {

    constructor($rootScope,
                $compile,
                $timeout,
                $templateCache,
                $http,
                $q,
                ramphastosApplicationService,
                ramphastosPropertyChangedService,
                ramphastosFillViewScopeService,
                ramphastosDiffPatchService,
                ramphastosCyclicJsonService) {
        return {
            
            $$ngIsClass() { return true; }, // because: https://stackoverflow.com/a/47164318/3737186
            restrict: 'E',
            scope: {
                vmName: '@viewModel'
            },
            controller: [
                '$scope',
                '$rootScope',
                'ramphastosComService',
                'ramphastosDiffPatchService',
                'ramphastosPropertyChangedService',
                ramphastosViewController
            ],
            link: function(scope, element, attrs) {
                var viewModel,
                    timerViewModelChanged,
                    timerCollectionPropertyChanged,
                    designatedParent,
                    onLoadExpression = attrs.onLoad;

                //make sure view-model attribute is set
                if (scope.vmName === undefined) {
                    throw new Error("Missing attribute 'view-model' in ramphastos-view directive! " +
                        "Error happened in a ramphastos-view showing the view-model '" +
                        scope.$parent.vmName +
                        "'");
                }
                try {
                    designatedParent = ramphastosApplicationService.getParentRamphastosScope(scope);
                } catch (err) {
                    console.error(
                        "Error on ramphastos-view directive. Could not retrieve parent scope, aborting. The view-model " +
                        "parameter was '" +
                        scope.vmName +
                        "'",
                        err.stack);
                    return;
                }

                //get the view-model instance from the application-service
                try {
                    viewModel = ramphastosApplicationService.getViewModelByName(designatedParent, scope.vmName);
                    if (viewModel === null ||
                        viewModel === undefined ||
                        viewModel.RamphastosType !== 'RamphastosViewModel') {
                        var errorRamphastosPath =
                            ramphastosApplicationService.getRamphastosPathForRamphastosScope(designatedParent);
                        var errorMsg = "";
                        if (viewModel === undefined) {
                            //Note: in the case of a normal property this case is handled in the application-service
                            //that will throw an error with error-message contain a suggestion if applicable.
                            //this message might still appear in the case RamphastosHintObject (e.g. working with collection of view-models)
                            errorMsg = "No property with name '" +
                                scope.vmName +
                                "' exists on the parent view-model with" +
                                " RamphastosPath '" +
                                errorRamphastosPath +
                                "'! Please check make sure there are" +
                                " no spelling mistakes!";
                        } else if (viewModel === null) {
                            errorMsg = "Property with name '" +
                                scope.vmName +
                                "' was null on the parent view-model with" +
                                " RamphastosPath '" +
                                errorRamphastosPath +
                                "'! The view-model must not be null!";
                        } else if (viewModel.RamphastosType !== 'RamphastosViewModel') {
                            errorMsg = "Fatal error: view-model was not of type 'RamphastosViewModel'";
                        }
                        throw new Error(errorMsg);
                    }
                } catch (e) {
                    //Retrieving view-model instance from application-service failed, show error in view-model
                    console.error(e, e.stack);
                    viewModel = {
                        View: {
                            Template: "<strong>ERROR: Could not retrieve view-model instance!</strong>" +
                                "<p>Error message:</p>" +
                                "<pre>" +
                                e +
                                "</pre>"
                        }
                    };
                }


                var ramphastosPath = viewModel.RamphastosPath;

                element.on('$destroy',
                    function() {
                        if (scope.ramphastosChildScope) {
                            try {
                                scope.ramphastosChildScope.$destroy();
                            } catch (err) {
                                console.error("Error while destroying scope", err);
                            }

                        }
                        if (scope.unRegisterTemplateUrlChangedEventHandler !== undefined) {
                            try {
                                scope.unRegisterTemplateUrlChangedEventHandler();
                            } catch (watcherErr) {
                                console.error("Error while removing template change watcher", watcherErr);
                            }
                        }
                        scope.$destroy();
                    });

                scope.$on('$destroy',
                    function() {
                        if (timerViewModelChanged) {
                            $timeout.cancel(timerViewModelChanged);
                        }
                        if (timerCollectionPropertyChanged) {
                            $timeout.cancel(timerCollectionPropertyChanged);
                        }
                        ramphastosApplicationService
                            .unlinkRamphScopePair(scope); //remove link between ramphastosPath and scope.$id
                        //remove view-model from cache
                        ramphastosDiffPatchService.deleteViewModelCache(ramphastosPath); //TODO: improve

                        ramphastosApplicationService.removeHintObjectForFilterFromCache(ramphastosPath); //Remove potential correlate hint model cache
                    });

                var weAreWatching = false;
                var propertiesToWatch = null;
                //initial content loading

                /**
                 * Execute requests for removing and adding stylesheets to the HTML dom.
                 * This function is executed before the directive is linked and added to the HTML dom
                 * @param {Object} view instance
                 */
                var unloadAndLoadCss = function(view) {
                    if (view.OnLoadCssUnloadLinks !== undefined && view.OnLoadCssUnloadLinks !== null) {
                        $.each(view.OnLoadCssUnloadLinks,
                            function(key, value) {
                                $('link[href="' + value + '"]').disabled = true;
                                $('link[href="' + value + '"]').remove();
                            });

                    }
                    if (view.OnLoadCssLoadLinks !== undefined && view.OnLoadCssLoadLinks !== null) {
                        $.each(view.OnLoadCssLoadLinks,
                            function(key, value) {
                                $("<link/>",
                                    {
                                        rel: "stylesheet",
                                        type: "text/css",
                                        href: value
                                    }).appendTo("head");
                            });
                    }
                };

                var compileViewModel = function(myScope, myViewModel, onLoadExpression) {
                    var timerDigest, content;
                    if (myScope.ramphastosChildScope) {
                        //remove links before registering new links
                        //This is a heavy operation for big scopes! Here is some potential for performance improvement
                        ramphastosApplicationService.unlinkRamphScopePair(scope); //slow
                        ramphastosPropertyChangedService.unregisterAll(myScope
                            .ramphastosChildScope); //much faster than previous statements
                    }

                    //create new isolated scope that does not inherit anything
                    var childScope = $rootScope.$new(true, scope);

                    childScope.sync = myScope.sync;
                    childScope.syncPartial = myScope.syncPartial;

                    childScope.$on('$destroy',
                        function() {
                            if (timerDigest) {
                                $timeout.cancel(timerDigest);
                                timerDigest = null;
                            }
                            try {
                                if (!ramphastosApplicationService.getPreventApplicationModelCacheWriteback()) {
                                    //we also have to update the app-model cache with the current UI changes we have

                                    //Step 1
                                    var viewModelFull =
                                        ramphastosApplicationService.getViewModelFromAppModelCache(childScope.vm
                                            .RamphastosPath);
                                    //Step 2 - Apply changes we have in the current scope to this view-model (we have to check whether it really still exits)
                                    if (viewModelFull !== undefined &&
                                        viewModelFull !== null &&
                                        viewModelFull.Hash === myViewModel.Hash) {
                                        //case when only the view was deleted but the view-model still exists
                                        ramphastosApplicationService.dirtyCopy(childScope.vm, viewModelFull);
                                    }
                                }
                            } catch (e) {
                                console.error("Could not update app model cache when scope was destroyed.", e);
                            }
                            ramphastosPropertyChangedService.unregisterAll(childScope);
                        });

                    ramphastosApplicationService.linkRamphScopePair(myViewModel, scope);
                    ramphastosFillViewScopeService.fillScope(childScope, myViewModel);

                    //cache the view-model
                    ramphastosDiffPatchService.cacheViewModel(childScope.vm); //TODO: improve

                    //register watchers if required and remember some information about what we need to watch
                    if (myViewModel.WatchPropertyChanges) {
                        weAreWatching = true;
                        ramphastosPropertyChangedService.registerModelWatch(childScope, 'vm');
                    } else {
                        weAreWatching = false;
                        propertiesToWatch = myViewModel.RamphastosWatchProperties;
                        if (propertiesToWatch !== null) {
                            ramphastosPropertyChangedService.registerModelWatch(childScope, 'vm', propertiesToWatch);
                        }
                    }


                    var continueWithCompilation = function(viewModelTemplate, onLoadExpression) {
                        content = $compile(viewModelTemplate)(childScope);

                        element.html(content);

                        if (myScope.ramphastosChildScope && myScope.ramphastosChildScope !== childScope) {
                            //do only call destroy after the content has been replaced!!! Otherwise we have memory-leaks.
                            //(so $destroy should only be called after we have no more reference to the scope!)
                            myScope.ramphastosChildScope.$destroy();
                        }
                        myScope.ramphastosChildScope = childScope;

                        //see: http://stackoverflow.com/a/18996042
                        timerDigest = $timeout(function() {
                                childScope.$digest();
                                //run on load expression.
                                if (onLoadExpression !== undefined) {
                                    childScope.$parent.$parent
                                        .$eval(onLoadExpression); //TODO: create unit-test for this function.
                                }
                                //trigger the execution of possibly cached function calls
                                ramphastosApplicationService.executeQueuedVmJavaScriptFunctionCalls(myViewModel
                                    .RamphastosPath);
                            },
                            0,
                            false);
                    };

                    var view = myViewModel.View;
                    var viewModelTemplate;
                    if (view.TemplateUrl === undefined && view.Template !== undefined) {
                        //the case for the root-view-model
                        viewModelTemplate = view.Template;
                        childScope.templateSourceInfo = "hard coded or manual template."; //debug help
                    } else {
                        myScope.templateUrl = view.TemplateUrl;
                        childScope.templateSourceInfo = myScope.templateUrl; //debug help
                        myScope.unRegisterTemplateUrlChangedEventHandler = $rootScope.$on('ramTemplateChanged',
                            function(event, obj) {
                                if (myScope.templateUrl === obj) {
                                    $templateCache.remove(obj);
                                    $http({
                                        method: 'GET',
                                        //Here we need to add the locale such we can get a translated template
                                        url: myScope.templateUrl
                                    }).then(function successCallback(response) {
                                            $templateCache.put(myScope.templateUrl, response.data);
                                            //check whether the scope still exists, if not we nevertheless saved the template in the template store
                                            //because it is quite likely that the template will get used somewhere.
                                            if (ramphastosApplicationService.isRamphastosScope(scope)) {
                                                element.empty();
                                                var newContent = $compile(response.data)(myScope.ramphastosChildScope);
                                                element.html(newContent);
                                            }
                                        },
                                        function() {
                                            console.warn("could not load changed template. Change will be ignored.");
                                        });
                                }
                            });
                        viewModelTemplate = $templateCache.get(view.TemplateUrl);
                    }
                    //Execute onLoad and unLoadCSS requests
                    unloadAndLoadCss(view);

                    if (viewModelTemplate === undefined) {
                        //Before we load the sample we have to prefil the ramphastosChildScope variable because others might access it
                        //will the template is still loading.
                        myScope.ramphastosChildScope = childScope;
                        //We have to load the sample
                        var contentDeliveryUrl = ramphastosApplicationService.getContentDeliveryUrl();
                        var templateUrl = view.TemplateUrl;
                        if (contentDeliveryUrl !== undefined && contentDeliveryUrl !== null) {
                            templateUrl = contentDeliveryUrl + templateUrl;
                        }
                        $http({
                            method: 'GET',
                            //Here we need to add the locale such we can get a translated template
                            url: templateUrl
                        }).then(function successCallback(response) {
                                viewModelTemplate = response.data;
                                $templateCache.put(view.TemplateUrl, viewModelTemplate);
                                //check whether the scope still exists, if not we nevertheless saved the template in the template store
                                //because it is quite likely that the template will get used somewhere.
                                if (ramphastosApplicationService.isRamphastosScope(scope)) {
                                    continueWithCompilation(viewModelTemplate, onLoadExpression);
                                }
                            },
                            function errorCallback(response) {
                                //throw Error("Error retrieving template with url=" + view.TemplateUrl + ". Response: " + response);
                                viewModelTemplate = "<strong>ERROR: Could not retrieve view-model template!</strong>" +
                                    "<p>Request URL:</p>" +
                                    "<pre>" +
                                    view.TemplateUrl +
                                    "</pre>" +
                                    "<p>Response:</p>" +
                                    "<pre>" +
                                    "Status: " +
                                    response.status +
                                    "<br>" +
                                    "Status Text: " +
                                    response.statusText +
                                    "<br>" +
                                    "Data: " +
                                    response.data +
                                    "</pre>";
                                continueWithCompilation(viewModelTemplate, onLoadExpression);
                            });
                    } else {
                        continueWithCompilation(viewModelTemplate, onLoadExpression);
                    }
                };

                scope.currentPromise = null;

                scope.currentPromise = null;

                scope.viewModelChanged = function(myViewModel) {
                    var deferred = $q.defer();
                    //Changing view-model instead of recompiling seems not to give a performance advantage
                    //therefore we don't do it and reduce complexity here

                    //check if we have the same view-model because if we have we should not write back the value to the cache
                    //which would happen automatically triggered from the $destroy event

                    element.empty(); //Set view to empty so that there is a feedback that we are loading something
                    timerViewModelChanged = $timeout(function() {
                            compileViewModel(scope, myViewModel);
                            element.show();
                            deferred.resolve();
                            scope.currentPromise = null;
                            scope.$digest();
                        },
                        1,
                        false);
                    scope.currentPromise = deferred.promise;
                    return deferred.promise;
                };

                /**
                 * Update the view-model by applying a delta on the current view-model
                 * The reason this function requires a JSON string rather than the object itself is that it needs to
                 * copy the delta. Parsing it a second time is more efficient than copying it.
                 * @param {string} deltaJson JSON of delta object or array of delta objects
                 */
                scope.updateViewModelProperties = function(deltaJson) {
                    if (scope.currentPromise !== null) {
                        scope.currentPromise.then(function() {
                            scope.updateViewModelProperties(deltaJson);
                        });
                        return;
                    }

                    //we need to apply the delta and then update the cache
                    //Step 0 - Consistency check
                    if (scope.ramphastosChildScope.vm.RamphastosPath === undefined) {
                        throw Error("ramphastosPath was undefined when trying to apply delta.");
                    }

                    if (weAreWatching || propertiesToWatch !== null) {
                        //Do not watch while we are propagating changes from the client
                        ramphastosPropertyChangedService.unregisterAll(scope.ramphastosChildScope);
                    }

                    //Step 1 - Get version from app-model cache
                    var viewModelFull =
                        ramphastosApplicationService.getViewModelFromAppModelCache(scope.ramphastosChildScope.vm
                            .RamphastosPath);
                    if (viewModelFull === undefined || viewModelFull === null) {
                        throw Error("view-model from cache was null or undefined for RamphastosPath '" +
                            scope.ramphastosChildScope.vm.RamphastosPath +
                            "'");
                    }

                    var hashBefore = viewModelFull.Hash;
                    //Step 2 - Apply changes we have in the current scope to this view-model
                    ramphastosApplicationService.dirtyCopy(scope.ramphastosChildScope.vm, viewModelFull);
                    //Step 2a - Consistency check
                    if (hashBefore !== viewModelFull.Hash) {
                        throw Error("Hash unexpectetly changed from " + hashBefore + " to " + viewModelFull.Hash);
                    }
                    //Step 3 - Apply delta to both the view-model and the cache separately (which normally is much faster than copying the view-model!)
                    var deltaArray = ramphastosCyclicJsonService.fromCJSON(deltaJson);
                    var deltaArrayForCache = ramphastosCyclicJsonService.fromCJSON(deltaJson);

                    ramphastosDiffPatchService.patchArray(viewModelFull, deltaArray);
                    ramphastosDiffPatchService.patchCacheArray(scope.ramphastosChildScope.vm.RamphastosPath,
                        deltaArrayForCache);

                    //Step 4 - Update app-model cache
                    ramphastosApplicationService.updateAppModelCache(viewModelFull);
                    //Step 5 - Update ramph-scope link
                    ramphastosApplicationService.linkRamphScopePair(viewModelFull, scope);
                    //Step 6 - Fill the current scope
                    ramphastosFillViewScopeService.fillScope(scope.ramphastosChildScope, viewModelFull);

                    if (weAreWatching) {
                        ramphastosPropertyChangedService.registerModelWatch(scope.ramphastosChildScope, 'vm', null);
                    } else if (propertiesToWatch !== null) {
                        ramphastosPropertyChangedService.registerModelWatch(scope.ramphastosChildScope,
                            'vm',
                            propertiesToWatch);
                    }

                    //Step 7 - Look whether we need to update a hint object
                    ramphastosApplicationService.updateRelevantHintObjectIfRequired(scope.ramphastosChildScope.vm.RamphastosPath, viewModelFull);

                    // We call digest after we registered the watchers. Look up ramphastosPropertyChangedService.js for more details.
                    $timeout(function() {
                            scope.ramphastosChildScope.$digest();
                        },
                        0,
                        false);
                };

                compileViewModel(scope, viewModel, onLoadExpression);
            }
        };
    }
}
