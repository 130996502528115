import angular from 'angular';
import ramphastosDebugController from './../controllers/ramphastosDebugController';

export default class RamphastosDebug {

    // because: https://stackoverflow.com/a/47164318/3737186
    static get $$ngIsClass() { return true; }

    constructor($rootScope, $compile, $timeout) {
        return {
            restrict: 'E',
            scope: {
                model: '=',
                modelName: '=?',
                staticModules: '=',
                lazyModules: '=',
                rootSelected: '=',
                moduleList: '=?'
            },
            controller: [
                '$scope',
                '$rootScope',
                ramphastosDebugController
            ],
            templateUrl:
                "ramphastos/File/RamphastosUi.Core/RamphastosUi.Core.WebResources.angular.ramphastosApp.template/ramphastosDebug.html",
            link: function(scope, element, attrs) {
                if (angular.isUndefined(scope.modelName)) {
                    scope.modelName = attrs.model;
                }
                
                scope.moduleList = { ... { "static" : scope.staticModules }, ... { "lazy_loaded" : scope.lazyModules} }
                console.log(scope.moduleList);
            }
        };
    }
}
