export default class RamphastosDebugKeyValue {

    // because: https://stackoverflow.com/a/47164318/3737186
    static get $$ngIsClass() { return true; }

    constructor() {
        return {
            restrict: 'E',
            scope: {
                key: '=',
                value: '='
            },
            templateUrl:
                "ramphastos/File/RamphastosUi.Core/RamphastosUi.Core.WebResources.angular.ramphastosApp.template.ramphastosDebugKeyValue.html",
            link: function($scope, $element, $attrs) {
                $scope.edit = '';
                $scope.tmpValue = $scope.value;

                $scope.setEdit = function() {
                    $scope.edit = typeof $scope.value;
                };

                $scope.$watch(('value'),
                    function(newValue) {
                        $scope.tmpValue = newValue;
                    });

                $scope.$watch(('tmpValue'),
                    function(newValue) {
                        if (typeof newValue === typeof $scope.value) {
                            $scope.value = newValue;
                        }
                    });

                $scope.getClass = function(param) {
                    return 'debug-' + typeof param;
                };
            }
        };
    }
}