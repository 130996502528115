export default class RamphastosLocaleService {

    // because: https://stackoverflow.com/a/47164318/3737186
    static get $$ngIsClass() { return true; }

    constructor($window) {
        var locale = undefined;

        var literals = {}; //structure and usage: literals["hello world"] (They are loaded with the right locale)

        this.currentLocale = function () {
            return locale;
        };

        this.isReloadRequiredFromLocaleChange = function (newLocale) {
            var currentLocale = this.currentLocale();
            if ((currentLocale !== undefined && newLocale !== currentLocale)) {
                return true;
            }
            return false;
        };

        this.updateLocale = function (newLocale, forceReload) {
            var currentLocale = this.currentLocale();
            if (locale !== newLocale) {
                locale = newLocale;
            }
            if ((currentLocale !== undefined && locale !== currentLocale) || forceReload) {
                //we have a different locale, this can cause problems, e.g. JavaScript files
                //get loaded with different locals. A reload is the safest option
                //TODO: this is quite inefficient -> support fluent language change somewhen?
                //applicationModel.Locale = locale; //Theoretically this call is not needed for now
                $window.location.reload();
                //location.reload();
            }
        };

        this.setLocale = function (newLocale) {
            if (locale !== newLocale) {
                locale = newLocale;
            }
        };

        this.getLiteralTranslation = function (literal) {
            var translation = literals[literal];
            if (translation !== undefined) {
                return translation; //we have a translation for this literal
            }
            return literal;
        };

        this.loadJsonLiteralResources = function (jsonLiteralResource) {
            var url = jsonLiteralResource + "?locale=" + locale;
            $.ajax({
                //if cross-domain requests are allowed is defined on the application-model 
                crossDomain: true,
                dataType: "json",
                url: url,
                success: function (response) {
                    var loadedLiterals = angular.fromJson(response);
                    $.each(loadedLiterals, function (key, element) {
                        literals[key] = element;
                    });
                },
                error: function () {
                    console.error("error loading json literals : " + url);
                }
            });
        };
    }
}