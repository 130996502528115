import $ from 'jquery';

export default class RamphastosViewScopeFillService {

    // because: https://stackoverflow.com/a/47164318/3737186
    static get $$ngIsClass() { return true; }

    constructor(ramphastosComService, ramphastosApplicationService, ramphastosCopyService) {
        var processNode = function (directiveScopeViewModelNodeInstance, viewModelNodeInstance, nameprefix, directiveScope) {
            if (viewModelNodeInstance === undefined || viewModelNodeInstance === null) {
                throw Error("viewModelNodeInstance was null or undefined.");
            }
            $.each(viewModelNodeInstance, function (key, value) {
                if (value === undefined) return true;  //=continue
                if (value === null) {
                    directiveScopeViewModelNodeInstance[key] = null; //TODO: add unit test that ensures null values end up in the scope
                    return true;  //=continue
                }
                if (key === 'WatchPropertyChanges') return true;  //=continue
                if (key === 'RamphastosWatchProperties') return true;  //=continue
                if (key === 'RamphastosForFilterProperties') return true;  //=continue
                if (value.RamphastosType === undefined) {
                    if (value.constructor === Array) {
                        directiveScopeViewModelNodeInstance[key] = [];
                        $.each(value, function (innerKey, innerValue) {
                            if (innerValue !== undefined && innerValue !== null && innerValue.RamphastosType === "RamphastosViewModel") {
                                //add a hint object here
                                var hintObject =  {
                                    RamphastosType: "RamphastosViewModelHint",
                                    RamphastosPath: innerValue.RamphastosPath,
                                    Key: innerKey,
                                    Collection: key
                                };
                                // make values for filtering available if required
                                if (innerValue.RamphastosForFilterProperties !== null) {
                                    $.each(innerValue.RamphastosForFilterProperties, function(forFilterKey, forFilterValue) {
                                        hintObject[forFilterValue] = innerValue[forFilterValue]; //The value is actually the property name
                                    });
                                };
                                directiveScopeViewModelNodeInstance[key][innerKey] = hintObject;
                                ramphastosApplicationService.addHintObjectWithForFiltering(hintObject);
                            } else {
                                if (typeof innerValue === "object" && innerValue !== null) {
                                    if (innerValue.constructor === Array) {
                                        directiveScopeViewModelNodeInstance[key][innerKey] = [];
                                    } else {
                                        directiveScopeViewModelNodeInstance[key][innerKey] = {};
                                    }
                                    processNode(directiveScopeViewModelNodeInstance[key][innerKey], innerValue, nameprefix + key + "[" + innerKey + "].", directiveScope);
                                } else {
                                    directiveScopeViewModelNodeInstance[key][innerKey] = innerValue;
                                }
                            }
                        });
                    } else {
                        // only then add it to the scope;

                        // we only add copies to the scope, because otherwise you can build loops
                        // from JS to C# and back and forth, as the updates that flow back from C#
                        // get applied, as we only detach the scope watchers in the scope we are actually
                        // applying the changes. Alternative would be to have a global switch to stop syncing after the diffing
                        // would result in side-effects with collections e.g. when we add/remove elements

                        //directiveScopeViewModelNodeInstance[key] = ramphastosCopyService.copy(value);

                        // TODO: we suspect that the call above makes problems and let the application crash, we have no idea why and how... :-(
                        directiveScopeViewModelNodeInstance[key] = value;
                    }
                } else if (value.RamphastosType === 'RamphastosCommand') {
                    var ramphastosPath = value.RamphastosPath;
                    directiveScopeViewModelNodeInstance[key] = function () {
                        var parameters = Array.prototype.slice.call(arguments);
                        if (parameters.length > 1) {
                            console.warn(
                                "Only one parameter (that may be complex) is supported for exectue! Further arguments are ignored!");
                        }
                        ramphastosComService.execute(ramphastosPath, parameters[0]);
                    };
                } else if (value.RamphastosType === 'RamphastosCommandV2') {

                    if (directiveScopeViewModelNodeInstance[value.MethodName] === undefined) {
                        directiveScopeViewModelNodeInstance[value.MethodName] = function () {

                            var parameters = Array.prototype.slice.call(arguments);
                            var jsonizedParameters = [];
                            $.each(parameters,
                                function (parameterKey, parameterValue) {
                                    if (Array.isArray(parameterValue) ||
                                        Object.prototype.toString.call(parameterValue) === '[object Object]') {
                                        jsonizedParameters.push(JSON.stringify(parameterValue));
                                    } else {
                                        jsonizedParameters.push(parameterValue);
                                    }
                                });

                            return ramphastosComService.executeV2(value.TargetType,
                                value.TargetId,
                                value.MethodName,
                                value.ParameterTypes, //TODO: maybe get rid of this argument
                                jsonizedParameters);
                        };
                    } else {
                        //todo: 
                    }


                }

                //TODO: move to an implementation only supporting "vm.subviewmodel" and move to usage of hint-objects in every case
                //We also want to add normal view-models as hint object
                else if (value.RamphastosType === 'RamphastosViewModel') {
                    directiveScopeViewModelNodeInstance[key] = {
                        RamphastosType: "RamphastosViewModelHint",
                        RamphastosPath: value.RamphastosPath,
                        ViewModelName: nameprefix + key
                    };
                }
                else if (value.RamphastosType === 'RamphastosObject') {
                    if (typeof value === "object") {
                        directiveScopeViewModelNodeInstance[key] = {};
                        processNode(directiveScopeViewModelNodeInstance[key], value, nameprefix + key + ".", directiveScope);
                    }
                }
                else if (value.RamphastosType === 'RamphastosSharedObject') {
                    directiveScopeViewModelNodeInstance[key] = ramphastosApplicationService.getSharedObject(value.Id);
                }
                return true; //continue
            });
        }

        /**
        * Fill the scope with the view-model data and commands
        * @param {Object} directiveScope The scope to fill
        * @param {Object} viewModel The view-model to take the data from
        */
        this.fillScope = function(directiveScope, viewModel) {
            if (viewModel.RamphastosPath === null) {
                throw Error("RamphastosPath was null! This means a view-model was not linked!");
            }

            directiveScope.vm = {}; //put the viewModel in the scope

            //we have to register the new scope and the fill it with the values
            processNode(directiveScope.vm, viewModel, "", directiveScope);
        };
    }
}


