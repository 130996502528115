export default function RamphastosInterceptorFunction(ramphastosUrlLocalizeService) {
    return {
        'request': function(config) {
            config.url = ramphastosUrlLocalizeService.localize(config.url);

            return config;
        }
    };
}

