import $ from 'jquery';

export default class ramphastosPropertyChangedService {

    // because: https://stackoverflow.com/a/47164318/3737186
    static get $$ngIsClass() { return true; }

    constructor(ramphastosComService, ramphastosApplicaitonService, ramphastosDiffPatchService, ramphastosCyclicJsonService) {
        var watchCollection = {};

        this.sync = function(viewModel) {
            var delta = ramphastosDiffPatchService.getCacheDiff(viewModel);
            if (delta !== undefined) {
                if (delta !== null && delta.length > 100) {
                    console.warn(
                        "large delta detected. Please try to avoid large deltas as performance suffers in this case.");
                }
                //This copies the entire view-model, which seems to be fast enough.
                //Probably it would be faster to apply the delta to the cached version (the delta should be copied first)
                ramphastosDiffPatchService.cacheViewModel(viewModel);
                ramphastosComService.applyClientDiff(viewModel.RamphastosPath, delta);
            }
        };

        this.syncPartial = function(viewModel, tree, nodeChanges) {
            var delta = ramphastosDiffPatchService.getCacheDiffPartial(viewModel, tree, nodeChanges);
            if (delta !== undefined) {
                if (delta !== null && delta.length > 100) {
                    console.warn(
                        "large delta detected. Please try to avoid large deltas as performance suffers in this case.");
                }
                // Only copy the part of the view model in which changes were detected, otherwise this would make it
                // impossible to detect any other changes on the view model, which leads to nasty bugs.
                ramphastosDiffPatchService.cacheViewModelPartial(viewModel, tree);
                ramphastosComService.applyClientDiff(viewModel.RamphastosPath, delta);
            }
        };

        var createWatcherFunctionForDeepWatch = function (propertyName, viewModel) {
            var watcher = function (newValue, oldValue) {
                //Only after the first digest cycle this will be the case (where we don't need to sync the property)
                if (newValue !== oldValue) {
                    // ReSharper disable once MisuseOfOwnerFunctionThis (we use bind)
                    this.syncPartial(viewModel, [propertyName]);
                }
            }.bind(this);
            return watcher;
        }.bind(this);


        //We don't use deep watch, so the decycled copy doesn't get copied again by angular.
        //That's why we make a deep comparison using angular.equals ourselves, which is why we need to keep a copy of the old value.
        //The dummy value we return is changed when we detect a change, so angular triggers our watch function.
        var createWatcherDiffFunction = function (propertyName, viewModel) {
            var dummyValue = false;
            //Initialize the oldValue, else we could fail to detect changes during the first digest cycle.
            //Since the function below will be called (at least) two times during the first cycle anyway,
            //a additional call of decycle won't hurt much. Better safe than sorry.
            var oldValue = ramphastosCyclicJsonService.decycle(viewModel[propertyName]);
            return function () {
                var newValue = ramphastosCyclicJsonService.decycle(viewModel[propertyName]);
                if (!angular.equals(newValue, oldValue)) {
                    oldValue = newValue;
                    dummyValue = !dummyValue;
                }
                return dummyValue;
            };
        };


        //track changes in a property
        this.registerModelWatch = function (scope, viewModelName, propertiesToWatch) {
            var viewModel = scope[viewModelName];
            var registerWatchForProperty = function(key, value) {
                var unregisterFunction;
                //ignore some stuff
                if (key === "Template" ||
                    key === "RamphastosPath" ||
                    key === "RamphastosType" ||
                    key === "Type" ||
                    (value !== null && value.RamphastosPath !== undefined)) {
                    return;
                }
                if (watchCollection[scope.$id] === undefined) {
                    watchCollection[scope.$id] = { unregisterFunctions: [] };
                }
                if (value === undefined) {
                    console.error("Property " + key + " was undefined. Did not register watch function.");
                    return;
                }
                // Recursive watch register watchers on objects and arrays, but ignore RamphastosSharedObjects.
                if (value !== null &&
                ((typeof value === 'object' && value.RamphastosType !== 'RamphastosSharedObject') ||
                    value.constructor === Array)) {
                    //Don't use a deep watch, we do it ourselves.
                    unregisterFunction = scope.$watch(createWatcherDiffFunction(key, viewModel),
                        createWatcherFunctionForDeepWatch(key, viewModel));
                } else {
                    unregisterFunction = scope.$watch(viewModelName + "." + key,
                        createWatcherFunctionForDeepWatch(key, viewModel));
                }
                watchCollection[scope.$id].unregisterFunctions.push(unregisterFunction);
            };

            if (propertiesToWatch !== undefined && propertiesToWatch !== null) {
                $.each(propertiesToWatch, function (key, value) {
                    registerWatchForProperty(value, viewModel[value]);
                });
            } else {
                $.each(viewModel, registerWatchForProperty);
            }
        };

        this.unregisterAll = function(scope) {
            if (watchCollection[scope.$id] === undefined) return;
            $.each(watchCollection[scope.$id].unregisterFunctions,
                function(key, value) {
                    value(); //execute unregister function
                });
            delete watchCollection[scope.$id];
        };
    }
}


