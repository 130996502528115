export default class RamphastosRemoteSessionStorageService {

    storageIfAvailable(type) {
        try {
            var storage = window[type],
                x = '__storage_test__';
            storage.setItem(x, x);
            storage.removeItem(x);
            return true;
        }
        catch (e) {
            return false;
        }
    }

    ensureStorageAvailablitySet() {
        if (this.localeStorageAvailbe === undefined) {
            this.localeStorageAvailbe = this.storageIfAvailable('localStorage');
        }
        if (this.sessionStorageAvailbe === undefined) {
            this.sessionStorageAvailbe = this.storageIfAvailable('sessionStorage');
        }
    }

    constructor() {

        this.localeStorageAvailbe = undefined;
        this.sessionStorageAvailbe = undefined;


        // write to local-store
        this.storeToken = function(token) {
            this.ensureStorageAvailablitySet();
            if (this.localeStorageAvailbe) {
                localStorage.setItem('ramAccessToken', token);
            } else {
                return null;
            }
        };

        var tryReadTokenFromMetaData = function() {
            var token = $('meta[name=ram-access-token]').attr("content");
            if (token === null || token === undefined) {
                return null;
            }
            return token;
        };

        // storing in local-storage
        this.retriveToken = function() {
            this.ensureStorageAvailablitySet();
            var token;
            if (this.localeStorageAvailbe) {
                token = localStorage.getItem('ramAccessToken');
                if (token === null) {
                    token = tryReadTokenFromMetaData();
                    if (token !== null) {
                        this.storeToken(token);
                    }
                    return token;
                }
                return token;
            }
            //try to read from meta data
            token = tryReadTokenFromMetaData();
            return token;
        }.bind(this);

        // writing to session-storage
        this.storeWindowId = function(windowId) {
            this.ensureStorageAvailablitySet();
            if (this.sessionStorageAvailbe) {
                sessionStorage.setItem('ramWindowId', windowId);
            } else {
                return null;
            }
        };

        // This comes from here: https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
        // as discussed there it is not perfect from a cryptographic point of view but it's anyway sufficient for our purpose
        // (We just diffrent guids)
        this.createGuid = function() {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g,
                function(c) {
                    var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
                    return v.toString(16);
                });
        };

        var createGuid = this.createGuid;

        this.retriveWindowId = function() {
            this.ensureStorageAvailablitySet();
            var windowId = null;
            if (this.sessionStorageAvailbe) {
                windowId = sessionStorage.getItem('ramWindowId');
            }
            return windowId;
        }.bind(this);

        this.tryRetrieveWindowIdFromMetaData = function() {
            try {
                var token = $('meta[name=ram-window-id]').attr("content");
                if (token === null || token === undefined) {
                    return null;
                }
                return token;
            } catch (error) {
                console.log(error);
            }
            return null;
        };

        this.clear = function() {
            this.ensureStorageAvailablitySet();
            if (this.localeStorageAvailbe) {
                localStorage.removeItem('ramAccessToken');
            }
            if (this.sessionStorageAvailbe) {
                localStorage.removeItem('ramWindowId');
            }
        };


    }
}
