import angular from 'angular';


export default class RamphastosDebugItem {

    // because: https://stackoverflow.com/a/47164318/3737186
    static get $$ngIsClass() { return true; }

    constructor(RecursionHelper) {
        return {
            restrict: 'E',
            scope: {
                key: '=',
                data: '=',
                expanded: '=?',
                rootSelected: '='
            },
            templateUrl:
                "ramphastos/File/RamphastosUi.Core/RamphastosUi.Core.WebResources.angular.ramphastosApp.template.ramphastosDebugItem.html",
            controller: [
                '$scope',
                function($scope) {
                    $scope.expanded = angular.isDefined($scope.expanded) ? $scope.expanded : false;

                    $scope.$on('toggleExpand',
                        function(event, expand) {
                            $scope.expanded = expand;
                        });

                    $scope.getNumElements = function(obj) {
                        return Object.keys(obj).length;
                    };

                    $scope.isObject = function(param) {
                        return typeof param === 'object' && param !== null;
                    };

                    $scope.filterKeys = function(obj) {
                        var result = {};
                        angular.forEach(obj,
                            function(value, key) {
                                if (angular.isUndefined(value))
                                    return;

                                // hide RamphastosType property.
                                if (key === "RamphastosType" && value !== "RamphastosObject") {
                                    return;
                                }
                                // hide collections of sub-view-models.
                                if (value !== null && value.constructor === Array) {
                                    if (value.length > 0 && value[0].RamphastosType === "RamphastosViewModelHint") {
                                        return;
                                    }
                                }
                                // hide sub-viewmodels.
                                if (value !== null && (value.RamphastosType === "RamphastosViewModelHint")) {
                                    return;
                                }

                                //hide functions
                                if (typeof value === "function") {
                                    return;
                                }
                                result[key] = value;
                            });
                        return result;
                    };
                    
                    $scope.filterModules = function(obj) {
                        var result = {}
                        angular.forEach(obj, (value, key) => {
                            result[key] = value;
                        })
                        return result;
                    }
                }
            ],
            compile: function(element) {
                return RecursionHelper.compile(element);
            }
        };
    }
}