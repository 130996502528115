import $ from 'jquery';

export default class RamphastosExternal {

    // because: https://stackoverflow.com/a/47164318/3737186
    static get $$ngIsClass() { return true; }

    removeAllWatches() {
        if (this.removeUrlWatch !== undefined) {
            this.removeUrlWatch();
            this.removeUrlWatch = undefined;
        }
    }

    buildUrl($sce, externalUrl) {
        var url = $sce.trustAsResourceUrl(externalUrl);
        return url;
    }

    constructor($compile, $sce) {
        return {
            restrict: 'E',
            scope: {
                externalUrl: '@url',
                name:'@name'
            },
            link: function (scope, element) {

                element.on('$destroy',
                    function () {
                        this.removeAllWatches();
                        scope.$destroy();
                    }.bind(this));

                scope.$on('$destroy',
                    function () {
                        this.removeAllWatches();
                    }.bind(this));

                scope.externalUrlSecure = this.buildUrl($sce, scope.externalUrl);


                this.removeUrlWatch = scope.$watch('externalUrl',
                    function () {
                        scope.externalUrlSecure = this.buildUrl($sce, scope.externalUrl);
                    }.bind(this));

                var namePart;
                if (scope.name !== undefined || scope.name !== null) {
                    namePart = ' name="' + scope.name + '"';
                } else {
                    namePart = '';
                }

                var content = $compile('<iframe' + namePart +' ng-src="{{externalUrlSecure}}" style="border: 0;width: 100%;height: 100%;"></iframe>')(scope);

                var attributes = $(element)[0].attributes;

                element.html(content);

                //take over all attributes
                $.each(attributes,
                    function (key, value) {
                        if (value.nodeName === 'name' || value.nodeName === 'url') {
                            return; //continue;
                        }
                        $(element[0].children[0]).attr(value.nodeName, value.nodeValue);
                    });

              
            }.bind(this)
        }
    }
}

