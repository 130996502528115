import angular from 'angular';

export default class RamphastosInspectorItem {

    // because: https://stackoverflow.com/a/47164318/3737186
    static get $$ngIsClass() { return true; }

    constructor(RecursionHelper) {
        return {
            restrict: 'E',
            scope: {
                name: '=',
                subViewModels: '=',
                expanded: '=?'
            },
            templateUrl:
                "ramphastos/File/RamphastosUi.Core/RamphastosUi.Core.WebResources.angular.ramphastosApp.template.ramphastosInspectorItem.html",
            compile: function($element) {
                return RecursionHelper.compile($element);
            },
            controller: [
                '$scope', function($scope) {
                    $scope.expanded = angular.isDefined($scope.expanded) ? $scope.expanded : false;

                    $scope.$on('toggleViewModels',
                        function(event, expand) {
                            $scope.expanded = expand;
                        });

                    $scope.selectViewModel = function($event) {
                        if (typeof $scope.subViewModels.RamphastosPath !== 'undefined') {
                            $scope.$emit('viewModelSelected', $scope.subViewModels.RamphastosPath);
                        }
                        $event.stopPropagation();
                    };

                    $scope.hasSubViewModels = function(viewModel) {
                        for (var key in viewModel) {
                            if (key !== "RamphastosPath") {
                                return true;
                            }
                        }
                        return false;
                    };
                }
            ]
        };
    }
}