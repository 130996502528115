import $ from 'jquery';

import ramphastosSignalRComService from './angular/ramphastosApp/services/ramphastosSignalRComService';
import ramphastosRemoteSessionStorageService from './angular/ramphastosApp/services/ramphastosRemoteSessionStorageService';
import ramphastosBootstrap from '../../RamphastosUi.Core/WebResources/entry.js';


window.ramphastosInitServerLink = (ramphastosApp) => {
    ramphastosApp.service('ramphastosRemoteSessionStorageService',
        [ramphastosRemoteSessionStorageService]);
    ramphastosApp.service('ramphastosSignalRComService',
        ['$rootScope',
            '$timeout',
            '$interval',
            '$http',
            '$q',
            '$injector',
            '$location',
            '$templateCache',
            'ramphastosApplicationService',
            'ramphastosEncodingService',
            'ramphastosCyclicJsonService',
            'ramphastosResourceUpdateService',
            'ramphastosRemoteSessionStorageService',
            'ramphastosHTMLService',
            'ramphastosStringSplitService',
            'ramphastosLocaleService',
            ramphastosSignalRComService]);




};


window.ramphastosServer = 'SignalR';

// make sure webpack would not "destroy" this by using a dynamic variable name.. That's a real hack
window["jQ" + "uery"] = $; // without this the signalR hubs would not be initialized correctly. SignalR hubs need this
global.$ = global.jQuery = $;



$.get("ramphastos/ramphastosBootstrap", function (jsonData) {
    // we have to be sure first that the signalr/hubs are 
    $(document).ready(() => {
        let data = JSON.parse(jsonData);
        ramphastosBootstrap(data.AdditionalModules, data.AngularDigestTtl, data.EnableAngularDebug, data.EnableHtml5Mode);
    });
});

