export default class RamphastosComService {

    // because: https://stackoverflow.com/a/47164318/3737186
    static get $$ngIsClass() { return true; }

    constructor($window, $injector) {
        if ($window.ramphastosServer === 'CefGlue') {
            return $injector.get('ramphastosCefGlueComService');
        }
        if ($window.ramphastosServer === 'SignalR') {
            return $injector.get('ramphastosSignalRComService');
        }
        throw new Error('Unknown ramphastos server. Could not create communication service');
    }
}
