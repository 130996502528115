export default class RamphastosRoutingService {

    // because: https://stackoverflow.com/a/47164318/3737186
    static get $$ngIsClass() { return true; }

    constructor($rootScope, $timeout, $q, $location, $window) {
        /*
        * Changing the $location will trigger an event handler in ramphastosMainCtrl
        * which will trigger calling the com-service that will update the routing on 
        * server side.
        */


        let enabled = true;

        this.enable = function() {
            enabled = true;
        };

        this.disable = function() {
            enabled = false;
        };

        this.processRouteChangeFromServer = function(routingPath) {
            const deferred = $q.defer();
            if (enabled) {
                $timeout(redirectTo, 0, true, routingPath).then(
                    (val) => deferred.resolve(val)
                );
            } else {
                deferred.resolve();
            }
            return deferred.promise;
        };

        this.initRouting = function(applicationModelRoutingUrl) {
            this.processRouteChangeFromServer(applicationModelRoutingUrl);
        }.bind(this);

        function redirectTo(routingPath) {
            //check what is the current routing, only trigger a change of route if the route is really different
            const currentRoute = $location.path();
            routingPath = addLeadingForwardSlashIfMissing(routingPath);
            if (currentRoute !== routingPath) {
                $location.path(routingPath);  // It does not cause a full page reload when the browser URL is changed
                $rootScope.$apply();
            }
            return $location.path();
        }

        function addLeadingForwardSlashIfMissing(path) {
            return path.startsWith('/') ? path : "/" + path;
        }
    }
}