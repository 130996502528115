import $ from 'jquery';

export default class RamphastosMainController {
    constructor($scope, $rootScope, $location, ramphastosComService, ramphastosApplicationService) {

        $scope.WindowTitle = "loading...";
        ramphastosComService.initialize($scope, $location); //initialize service

        //This is the main controller, the only one watching it
        $rootScope.$on('appModelChanged',
            (e, appModel) => {
                $scope.WindowTitle = appModel.WindowTitle; //manually set the title
                //$scope.$digest(); //TODO:think about this, has to be disabled when promises where used for lazy loading ng modules
            });

        //initialize routing by registering to location changed event
        $rootScope.$on('$locationChangeSuccess',
            () => {
                var path = $location.path();
                if (path === undefined || path === null) {
                    return;
                }
                //remove '/'characters
                while (path !== undefined && path !== null && path.charAt(0) === '/') {
                    path = path.substr(1);
                }
                //look whether the new route is the same we already have (happens when route change is triggered from server)
                var currentRoutingUrl = ramphastosApplicationService.getCurrentRoute();
                if (currentRoutingUrl === path) {
                    return;
                }
                if (currentRoutingUrl !== null) {
                    //otherwise we would trigger the update on application startup which makes no sense 
                    // (and also com-service is not yet initialized)
                    ramphastosComService
                        .updateRoutingUrlFromClient(path); //TODO: gets called also when we click around, please improve
                }
            });

        $scope.requestResyncAfterError = function() {
            ramphastosComService.requestResync("root");
        };

        //register events about focus/blur
        window.onfocus = function() {
            ramphastosComService.updateHasFocus(true);
        };

        window.onblur = function() {
            ramphastosComService.updateHasFocus(false);
        };

        $(document).ready(function() {
            if (document.hasFocus()) {
                ramphastosComService.updateHasFocus(true);
            } else {
                ramphastosComService.updateHasFocus(false);
            }
        });
    }
}