export default class RamphastosInspector {

    // because: https://stackoverflow.com/a/47164318/3737186
    static get $$ngIsClass() { return true; }

    constructor($rootScope, ramphastosApplicationService, $timeout, ramphastosComService, ramphastosStaticModulesInfoService) {
        return {
            restrict: 'E',
            scope: {},
            templateUrl: "ramphastos/File/RamphastosUi.Core/RamphastosUi.Core.WebResources.angular.ramphastosApp.template.ramphastosInspector.html",
            link: function ($scope, $element, $attrs) {
                //set up functions and variables.
                var div;
                $scope.show = false;

                $scope.toggleShow = function () {
                    $scope.show = !$scope.show;
                    $scope.$digest();
                    //reattache listener for mousedown
                    if ($scope.show) {
                        div = $($element).find('.ramph-inspector');
                        div.mousedown(mousedown);
                        compileTree();
                    }
                };

                $scope.expandAll = function () {
                    $scope.$broadcast('toggleViewModels', true);
                };

                $scope.collapseAll = function () {
                    $scope.$broadcast('toggleViewModels', false);
                };

                $scope.tree = {};
                $scope.ramphastosScope = {};

                $scope.viewModelName = "root";


                $scope.lazyLoadedModules = ramphastosApplicationService.getLazyLoadedModules();
                $scope.staticLoadedModules = formatModuleList(ramphastosStaticModulesInfoService.getStaticModules());
                
                $scope.rootSelected = false;

                function formatModuleList(list) {
                    var result = {};
                    
                    for (const [key, value] of Object.entries(list)) {
                        result[value.Name] = value.ModuleFiles;
                    }
                    
                    return result;
                }
                
                function setViewModel(ramphastosPath) {
                    var parentScoope = ramphastosApplicationService.getScope(ramphastosPath, false);
                    if (parentScoope === undefined) {
                        $scope.ramphastosScope = {};
                        $scope.viewModelName = "Could not load view-model (not yet displayed?).";
                        return;
                    }
                    $scope.ramphastosScope = parentScoope.ramphastosChildScope;
                    $scope.viewModelName = ramphastosPath;
                    
                    $scope.rootSelected = $scope.viewModelName === 'root';
                }
                setViewModel('root'); //set RootViewModel as default.

                function compileTree() {
                    $scope.tree = ramphastosApplicationService.getViewModelTree();
                }
                compileTree();

                $scope.updateTree = function () {
                    compileTree();
                };

                $scope.templatesAreIndicated = false;

                $scope.indicateTemplates = function () {
                    $scope.templatesAreIndicated = !$scope.templatesAreIndicated;
                    //var relevantElement = $("ramphastos-view:not(#messageBoxRootItem)  > div:first-child");
                    var relevantElement = $("ramphastos-view:not(#messageBoxRootItem) > :first-child");
                    if ($scope.templatesAreIndicated) {
                        relevantElement.addClass("ramph-debug-template-indication");
                        relevantElement.each(function (index, value) {
                            var ramphastosScope = angular.element(value).scope();
                            angular.element(value).prepend('<span class="ramph-debug-template-info">' + ramphastosScope.templateSourceInfo + '</span>');
                            //angular.element(value).prepend("<p>Test</p>");
                        });
                    } else {
                        relevantElement.removeClass("ramph-debug-template-indication");
                        $('.ramph-debug-template-info').remove();
                    }

                }

                //register listeners.
                $scope.$on('viewModelSelected', function (event, id) {
                    setViewModel(id);
                });

                var listener = $rootScope.$on('viewModelChanged', function (event, vm) {
                    try {
                        if ( vm !== undefined && vm !== null && vm.RamphastosPath !== null && vm.RamphastosPath === $scope.ramphastosScope.vm.RamphastosPath) {
                            //the view model is updated asynchronously, so we have to wait a bit.
                            $timeout(function () {
                                compileTree();
                                setViewModel(vm.RamphastosPath);
                            },
                                100,
                                false);
                        }
                    } catch (error) {
                        console.warn("Error while handling view-model changed event", error.stack);
                    }
                });

                //since we've registered the listener on $rootScope we need to unbind it manually.
                $scope.$on('$destroy', listener);

                //make the inspector draggable.
                div = $($element).find('.ramph-inspector');

                var left = 20;
                var top = 20;

                var lastX = 0;
                var lastY = 0;
                var isDragged = false;

                $scope.top = top + 'px';
                $scope.left = left + 'px';

                function mousedown(event) {
                    //ignore clicks inside .debug-div
                    var target = $(event.target);
                    if (target.parentsUntil(div, ".debug-div").length === 0 && !target.is(".debug-div")) {
                        isDragged = true;
                    }
                }

                function mouseup() {
                    isDragged = false;
                }

                function mousemove(event) {
                    if (isDragged) {
                        var deltaX = event.pageX - lastX;
                        var deltaY = event.pageY - lastY;

                        left += deltaX;
                        top += deltaY;

                        $scope.top = top + 'px';
                        $scope.left = left + 'px';
                        $scope.$digest();

                        //stop propagation of the event so no text is accidentally selected.
                        if (event.stopPropagation) event.stopPropagation();
                        if (event.preventDefault) event.preventDefault();
                        event.cancelBubble = true;
                        event.returnValue = false;
                    }
                    lastX = event.pageX;
                    lastY = event.pageY;
                }

                function keyShortcutListener(event) {
                    if (event.which === 82 && event.ctrlKey && event.altKey) {
                        isDragged = false; //make sure inspector isn't dragged when opened.
                        $scope.toggleShow();
                    }
                }

                //register events.
                div.mousedown(mousedown);
                $(document).mouseup(mouseup);
                $(document).mousemove(mousemove);
                $(document).keydown(keyShortcutListener);

                //unregister events on destroy.
                $scope.$on('$destroy', function () {
                    div.off('mousedown', mousedown);
                    $(document).off('mouseup', mouseup);
                    $(document).off('mousemove', mousemove);
                    $(document).off('keydown', keyShortcutListener);
                });

                $scope.showScreenSharingLink = function () {
                    $scope.screenSharingUrl = ramphastosComService.getScreenSharingLink();
                }
            }
        }
    }
}