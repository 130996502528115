export default class RamphastosComStatus {

    
    static get $$ngIsClass() { return true; }

    constructor($rootScope, $timeout, $window) {

        var handleStatusChange = (scope, $timeout, data) => {
            scope.comStatus = status;
            if (data.status === "connected") {
                scope.coverAll = false;
                scope.statusMessage = "Connected.";
            }
            if (data.status === "disconnected") {
                scope.coverAll = true;
                if (data.message !== undefined && data.message !== null) {
                    scope.statusMessage = data.message;
                } else {
                    scope.statusMessage = "Connection was lost. Please try to reload.";
                }
                $("#ramphastos_root").remove();
                $("#messageBoxRootItem").remove();
            }
            if (data.status === "reconnecting") {
                scope.coverAll = true;
                scope.statusMessage = "Trying to reconnect.";
            }
            if (data.status === "continuouslyReconnect") {
                scope.coverAll = true;
                scope.statusMessage = "Connection was lost. Trying to reestablish connection in a few seconds.";
            }
            if (data.status === "error") {
                scope.coverAll = true;
                scope.statusMessage = data.message;
            }
            $timeout(function() {
                scope.$apply();
            });
        };

        return {
            restrict: 'E',
            scope: true,
            link: function (scope) {
                scope.coverAll = false;
                $rootScope.$on('connectionStatusChanged',
                    function (event, data) {
                        console.log('connection status changed. New status: ' + data.status);
                        handleStatusChange(scope, $timeout, data);
                    });
            },
            $$ngIsClass() { return true; }, // because: https://stackoverflow.com/a/47164318/3737186
            templateUrl:
                "ramphastos/File/RamphastosUi.Core/RamphastosUi.Core.WebResources.angular.ramphastosApp.template/ramphastosComStatus.html"
        };
    }
}