export default class RamphastosUrlLocalizeService {

    // because: https://stackoverflow.com/a/47164318/3737186
    static get $$ngIsClass() { return true; }

    constructor(ramphastosLocaleService) {
        this.localize = function(url) {

            var locale = ramphastosLocaleService.currentLocale();

            if (!locale) {
                locale = "en-US";
            }
            if (url && url.indexOf("ramphastos/File/") !== -1) {
                var lastUrlPart = url.split(".").pop();

                if (/^(less|html|htm|js)((\?|#).*)?$/.exec(lastUrlPart) !== null &&
                    lastUrlPart.indexOf("locale=") === -1) {
                    if (lastUrlPart.indexOf("?") !== -1) {
                        url = url + "&locale=" + locale;
                    } else {
                        url = url + "?locale=" + locale;
                    }
                }
            }

            return url;
        };
    }
}