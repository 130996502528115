export default class RamphastosSuggestionService {

    // because: https://stackoverflow.com/a/47164318/3737186
    static get $$ngIsClass() { return true; }

    levenshtein(a, b) {
        if (a.length === 0) return b.length;
        if (b.length === 0) return a.length;

        // swap to save some memory O(min(a,b)) instead of O(a)
        if (a.length > b.length) {
            var tmp = a;
            a = b;
            b = tmp;
        }

        var row = [];
        // init the row
        var i;
        for (i = 0; i <= a.length; i++) {
            row[i] = i;
        }

        // fill in the rest
        for (i = 1; i <= b.length; i++) {
            var prev = i;
            for (var j = 1; j <= a.length; j++) {
                var val;
                if (b.charAt(i - 1) === a.charAt(j - 1)) {
                    val = row[j - 1]; // match
                } else {
                    val = Math.min(row[j - 1] + 1, // substitution
                        prev + 1,     // insertion
                        row[j] + 1);  // deletion
                }
                row[j - 1] = prev;
                prev = val;
            }
            row[a.length] = prev;
        }

        return row[a.length];
    }

    constructor() {
        this.getSuggestion = function(names, name) {
            var lowestLevensheinDistanceName = null;
            var lowestLevenshteinDistance = 100000;
            // ReSharper disable once MissingHasOwnPropertyInForeach (we want to iterate the properties in case names is an object!)
            for (var entry in names) {
                var levenshteinDistance = this.levenshtein(entry, name);
                if (levenshteinDistance < lowestLevenshteinDistance) {
                    lowestLevenshteinDistance = levenshteinDistance;
                    lowestLevensheinDistanceName = entry;
                }
            };
            if (lowestLevenshteinDistance < 4) {
                return lowestLevensheinDistanceName;
            }
            return null;
        };
    }
}