import $ from 'jquery';
var jsondiffpatch = require('jsondiffpatch');



export default class RamphastosDiffPatchService {

    // because: https://stackoverflow.com/a/47164318/3737186
    static get $$ngIsClass() { return true; }

    constructor(ramphastosCopyService) {
        var vmCache = {}, //Cache each view-model
            jsondiffpatchInstance;

        jsondiffpatchInstance = jsondiffpatch.create({
            textDiff: {
                //this is a hack until we also use the google-diff-match-patch text diffing algorythm on C# side
                //TODO: implement RAM-111
                minLength: 1000000
            },
            propertyFilter: function (name, context) {
                /*
                 this optional function can be specified to ignore object properties (eg. volatile data)
                  name: property name, present in either context.left or context.right objects
                  context: the diff context (has context.left and context.right objects)
                */
                return name !== '$$hashkey';
            }
        });

        //custom filter to ignore function variables (see https://github.com/benjamine/jsondiffpatch/blob/master/docs/plugins.md)
        var functionDiffFilter = function (context) {
            if (typeof context.left === 'function' || typeof context.right === 'function') {
                context.setResult(undefined); //see https://github.com/benjamine/jsondiffpatch/issues/112
                context.exit();
            }
        };

        var isLoop = function (context) {
            var i = 0;
            var currentParentContext = context.parent;
            while (currentParentContext !== undefined && currentParentContext !== null) {
                if (currentParentContext.left === context.left
                    && currentParentContext.right === context.right) {
                    return true;
                }
                currentParentContext = currentParentContext.parent;
                i++;
                if (i >= 100000) {
                    throw Error("fatal error in loop detection");
                }
            }
            return false;
        }

        //custom filter to handle cyclic loop
        var loopDetectionFilter = function (context) {
            if (typeof context.left === 'object' && typeof context.right === 'object') {
                if (isLoop(context)) {
                    context.setResult(undefined);
                    context.exit();
                }
            }
        }

        functionDiffFilter.filterName = 'function';
        loopDetectionFilter.filterName = 'loopDetection';

        jsondiffpatchInstance.processor.pipes.diff.before('trivial', functionDiffFilter);
        jsondiffpatchInstance.processor.pipes.diff.before('trivial', loopDetectionFilter);


        this.cacheViewModel = function(viewModel) {
            vmCache[viewModel.RamphastosPath] = ramphastosCopyService.copy(viewModel);
        };

        this.cacheViewModelPartial = function(viewModel, tree) {
            if (tree.length === 0) {
                this.cacheViewModel(viewModel);
                return;
            }

            var partialViewModel = viewModel;
            $.each(tree,
                function(key, value) {
                    partialViewModel = partialViewModel[value];
                });
            var partialViewModelCopy = ramphastosCopyService.copy(partialViewModel);

            var partialCachedViewModel = vmCache[viewModel.RamphastosPath];
            $.each(tree.slice(0, -1),
                function(key, value) {
                    partialCachedViewModel = partialCachedViewModel[value];
                });
            partialCachedViewModel[tree[tree.length - 1]] = partialViewModelCopy;
        };

        this.deleteViewModelCache = function(ramphastosPath) {
            delete vmCache[ramphastosPath];
        };

        /*var cleanUpDelta = function(delta, parent) {
            if (delta == undefined) return;
            $.each(delta, function(key, value) {
                if (key === "$$hashKey") {
                    delete parent[key];
                };
                cleanUpDelta(value, delta);
            });
        }*/

        this.getCacheDiff = function(viewModel) {
            var cachedViewModel = vmCache[viewModel.RamphastosPath];
            var delta = jsondiffpatchInstance.diff(cachedViewModel, viewModel);
            //we have to get rid of $$hasKey elements
            //cleanUpDelta(delta, null); //Info: we now ignore it on client side
            return delta;
        };

        this.getCacheDiffPartial = function(viewModel, tree, newValue) {
            if (Object.prototype.toString.call(tree) !== '[object Array]') {
                throw Error("parameter 'tree' must be of type array!");
            }
            var cachedViewModel = vmCache[viewModel.RamphastosPath];
            var partialCachdedViewModel = cachedViewModel;
            $.each(tree,
                function(key, value) {
                    partialCachdedViewModel = partialCachdedViewModel[value];
                });
            if (newValue === undefined) {
                //parameter not given, we will build it ourself
                newValue = viewModel;
                $.each(tree,
                    function(key, value) {
                        newValue = newValue[value];
                    });
            }
            var delta = jsondiffpatchInstance.diff(partialCachdedViewModel, newValue);
            if (delta === undefined) {
                return; //TODO: why
            }
            var completeDelta = {}
            var completeDeltaStep = completeDelta;
            $.each(tree,
                function(index, value) {
                    if (index === tree.length - 1) {
                        completeDeltaStep[value] = delta;
                    } else {
                        completeDeltaStep[value] = {};
                        completeDeltaStep = completeDeltaStep[value];
                    }
                });
            return completeDelta;
        };


        this.patchArray = function(vm, deltaArray) {
            $.each(deltaArray,
                function(index, value) {
                    jsondiffpatchInstance.patch(vm, value);
                });
        };

        this.patchCacheArray = function(ramphastosPath, deltaArray) {
            $.each(deltaArray,
                function(index, value) {
                    jsondiffpatchInstance.patch(vmCache[ramphastosPath], value);
                });
        };

        this.getFromCache = function(ramphastosPath) {
            return ramphastosCopyService.copy(vmCache[ramphastosPath]);
        };
    }
}

