export default class RamphastosDownload {

    // because: https://stackoverflow.com/a/47164318/3737186
    static get $$ngIsClass() { return true; }

    constructor(ramphastosComService, ramphastosApplicationService) {
        return {
            restrict: 'AE',
            scope: {},
            link: function (scope, element, attrs) {
                element.on('click', onChange);

                scope.$on('destroy', function () {
                    element.off('click', onChange);
                });

                //TODO: provide another alternative where we just select the file and first have to press a button to start the load.
                function onChange() {
                    var ramphastosPath = ramphastosApplicationService.getRamphastosPath(scope);
                    var fileprovider = attrs["fileprovider"];
                    fileprovider = fileprovider.replace(/vm\./g, "");

                    ramphastosComService.downloadFile(ramphastosPath, fileprovider);
                    element[0].value = ""; //allow to select the same file twice
                }
            }
        };
    }
}
