export default class RamphastosViewController {
    constructor($scope, $rootScope, ramphastosComService, ramphastosDiffPatchService, ramphastosPropertyChangedService) {
        //The execute function
        $scope.execute = function (command) {
            throw new Error("This function is no longer supported. Use 'scope.vm.MyCommand(myParam)' " +
                "instead. The error appeared when trying to execute command with property-name: " + command);
        };

        //TODO: create unit-test for this
        $scope.sync = function () {
            var viewModel = $scope.ramphastosChildScope.vm;
            ramphastosPropertyChangedService.sync(viewModel);
        };

        //TODO: create unit-test for this
        $scope.syncPartial = function (tree, newValue) {
            var viewModel = $scope.ramphastosChildScope.vm;
            ramphastosPropertyChangedService.syncPartial(viewModel, tree, newValue);
        };
    }
}